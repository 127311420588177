.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  color: #ccc;
  /* background: linear-gradient(200deg, #000, 70%, blueviolet); */
  /* background: linear-gradient(30deg, royalblue, 60%, #eee); */
  /* background: linear-gradient(30deg, #000, 60%, #eee); */
  background: linear-gradient(180deg, teal, 40%, #000);
  overflow: hidden;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bold {
  font-weight: bold;
}

#tsparticles {
  height: 100vh;
  width: 100vw;
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}

#dev-info {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10vw;
}

#dev-name {
  font-size: 7em;
}

#dev-title {
  font-size: 4em;
  color: goldenrod;
  line-height: 1.5;
}

#dev-info > a {
  text-decoration: none;
}

a #dev-title:hover {
  text-decoration: underline;
}

/* ipad: portrait */
@media (max-width: 800px) {
  #dev-info {
    margin: 0 auto;
    align-items: center;
  }
  #dev-name {
    font-size: 4em;
  }
  #dev-title {
    font-size: 1.6em;
  }
}

/* iphone: portrait */
@media (max-width: 400px) {
  #dev-name {
    font-size: 2.5em;
  }
  #dev-title {
    font-size: 1.3em;
  }
}
/* iphone: landscape */
@media (max-height: 400px) and (orientation: landscape) {
  #dev-name {
    font-size: 2.5em;
  }
  #dev-title {
    font-size: 1.3em;
  }
}
